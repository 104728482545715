<template>
  <div class="file-search">
    <div class="list">
      <div v-for="item in list" :key="item.id" class="item">
        {{item.memorialTablet}}
      </div>
    </div>
    <el-button style="width: 100%;" type="danger" @click="back">返回</el-button>
  </div>
</template>

<script>
export default {
  name: "FileSearch",
  props: {
    phone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      params: {
        phone: this.phone,
        pageNum: 1,
        pageSize: 99999
      },
      list: []
    }
  },
  methods: {
    async onLoad() {
      let res = await this.$http.get( '/memorial-tablet/page', {params: this.params})
      console.log(res.data.data.list)
      if (res.data.success) {
        this.list = res.data.data.list
      }
    },
    back() {
      this.$router.push({name: 'FileSearch'})
    }
  },
  created() {
    this.onLoad()
  }
}
</script>

<style scoped>
.file-search {
  background: #f6f6f6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list {
  display: flex;
  /*padding: 75px 28px;*/
  padding: 40px 28px;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 90%;
  height: 600px;
  overflow: scroll;
}

.item {
  width: 144px;
  height: 66px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 0px rgba(192, 192, 192, 0.27);
  border-radius: 5px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

</style>